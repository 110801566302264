<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="list">
						<div class="item" v-for="role in roles" @click="viewItem(role)" v-bind:class="{active: roleSelected.id == role.id}">
							<div class="name">{{ role.name }}</div>
							<div class="actions">
								<button @click.stop="editRole(role)"><icon-edit /></button>
								<button @click.stop="showModal('delete', {type: 'role', from: 'index_roles', model: role})"><icon-trash /></button>
							</div>
						</div>
						<div class="item-empty" v-if="!roles.length">
							{{ $t('roles.any_result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon"><icon-role /></div>
								<div class="name">{{ $t('roles.create-new-role') }}</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('roles.name') }}*</label>
										<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
										<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('roles.name_placeholder')" class="input-text no-icon" v-model="name">
									</div>
								</div>

								<div class="input-group">
									<div class="input-box bg-white">
										<textarea rows="3" :placeholder="$t('roles.description_paceholder')" v-model="description"></textarea>
									</div>
								</div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{$t('general.add')}}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon"><icon-role /></div>
								<div class="name">{{ roleSelected.name }} {{$t('roles.role')}}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="resetToList('item')"><icon-close /></button>
								</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{$t('roles.edit_role')}}</label>
									</div>
									<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('roles.name_placeholder')" class="input-text no-icon" v-model="name">
									</div>
								</div>

								<div class="input-group">
									<div class="input-box">
										<textarea rows="3" :placeholder="$t('roles.description_paceholder')" v-model="description"></textarea>
									</div>
								</div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{$t('general.update')}}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
								<div class="icon"><icon-role /></div>
								<div class="name">{{ roleSelected.name }} {{$t('roles.role')}}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editRole(roleSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
									<button class="btn-tbf white only-icon" @click="resetToList('item')"><icon-close /></button>
								</div>
							</div>
							<div class="data-item">
								<div class="description mb-0">{{ roleSelected.description }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconRole from '../../Icons/Role'
	import IconTrash from '../../Icons/Trash'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'

	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconRole,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				roles: [],
				roleSelected: '',
				name: '',
				description: '',
				error_message: '',
				errorsBe: {}
			}
		},
		async mounted(){
			await this.getRoles()

			setTimeout(() => {
				var title = this.$t('settings-navbar.roles');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshRolesIndex', () => {
				this.viewCrud = 'create'
				this.getRoles()
			})
		},
		validations: {
			name: {required}
		},
		methods: {
			async getRoles(){
				await axios.get( this.$auth.user().master_instance_id + '/roles')
				.then(({data}) => {
					this.roles = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = ''

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.name.charAt(0).toUpperCase() + this.name.slice(1),
						description: this.description
					}

					if(type == 'create'){
						this.createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/roles/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						this.getRoles()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.roleSelected = data.data;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			updateRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/roles/${this.roleSelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getRoles()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
							this.roleSelected = this.roles.find(el => el.id == this.roleSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editRole(role){
				this.scrollTop()
				this.viewCrud = 'edit'
				
				this.roleSelected = role
				this.name = role.name
				this.description = role.description
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.roleSelected = ''
				}
				this.name = ''
				this.description = ''
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.roleSelected = item
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>